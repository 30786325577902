import { API_GET, API_POST } from "./api.service"

export function replaceAndReadyAPI(api, prospect, user, phone) {

    api = api.replace('${phone}', phone);
    for (var p in user) {
        api = api.replace('${' + p + '}', user[p] ? user[p] : "");
    }

    for (var p in prospect) {
        api = api.replace('${' + p + '}', prospect[p] ? prospect[p] : "");
        api = api.replace('${' + p + ' }', prospect[p] ? prospect[p] : "");
        api = api.replace('${' + p + '\t}', prospect[p] ? prospect[p] : "");
        api = api.replace('${' + p.trim() + '}', prospect[p] ? prospect[p] : "");
    }

    return api;
}

export function CreateBody(SelectedTemplate, prospects) {
    if (SelectedTemplate) {
        let body = SelectedTemplate.body;

        for (var p in prospects) {
            body = body.replace('${' + p + '}', prospects[p] ? prospects[p] : "");
        }

        if (SelectedTemplate && SelectedTemplate.Files && SelectedTemplate.Files.length > 0) {
            let files = []
            for (let index = 0; index < SelectedTemplate.Files.length; index++) {
                const item = SelectedTemplate.Files[index];
                files.push(`<a target="_blank" href=${item.file}>${item.filename}</a>`)
            }
            let att = `<div>Attachments : ${files.join(",")}</div>`;

            body = body + att;
        }
        return body;
    }

    return "";
}

export async function sendCommByTemplate({
    email,
    bcc,
    SelectedTemplate,
    bodyContent,
    pid,
    phone,
    PhoneCode,
    prospect,
    user
}) {
    let registerRequest;
    let requestPayload;
    let type = SelectedTemplate.type;

    phone = phone.replace("+","");

    try {
        if (type == "email") {
            requestPayload = {
                commType: "email",
                msgObject: {
                    to: email,
                    bcc: bcc,
                    subject: SelectedTemplate.subject,
                    html: bodyContent
                },
                tid: SelectedTemplate._id,
                pid: pid
            }
        }
        if (type == "whatsapp") {
            requestPayload = {
                commType: "whatsapp",
                msgObject: {
                    to: phone,
                    text: bodyContent
                },
                tid: SelectedTemplate._id,
                pid: pid
            }

            try {
                if (SelectedTemplate.setup_id) {
                    await sendWhatsAppAndSMS(SelectedTemplate.setup_id, prospect, user, phone, type, encodeURIComponent(bodyContent))
                }
                else {
                    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
                    let wurl = "";
                    if (isMobile) {
                        wurl = "whatsapp://send?phone=" + (PhoneCode == 91 ? "91" : "") + phone + "&text=" + encodeURIComponent(bodyContent);
                    }
                    else {
                        wurl = "https://api.whatsapp.com/send?phone=" + (PhoneCode == 91 ? "91" : "") + phone + "&text=" + encodeURIComponent(bodyContent);
                    }
                    //console.log(wurl);
                    window.open(wurl, "_blank");
                    //window.location.href = wurl; // Change the current window's location
                    return 1;
                }
            }
            catch (e) {

            }
        }
        if (type == "sms") {
            requestPayload = {
                commType: "sms",
                msgObject: {
                    to: phone,
                    text: bodyContent
                },
                tid: SelectedTemplate._id,
                pid: pid
            }

            try {
                if (SelectedTemplate.setup_id) {
                    await sendWhatsAppAndSMS(SelectedTemplate.setup_id, prospect, user, phone, type, bodyContent)
                }
                else {

                }
            }
            catch (e) {
            }
        }

        registerRequest = await API_POST("comm/send", requestPayload);

        if (registerRequest && registerRequest.status == 200) {
            return 1
        }
        else {
            return 0;
        }
    } catch (error) { }


}





async function sendWhatsAppAndSMS(setup_id, prospects, user, phone, type, bodyContent) {
    let requestTime;
    let callingAPIRes;
    let api;
    let url;

    let registerRequest = await API_POST("action/getdata", {
        root: "CallingSetup",
        con: { _id: setup_id },
        cols: {},
    });

    if (registerRequest && registerRequest.status == 200 && registerRequest.data) {
        let callingSetup = registerRequest.data[0];
        api = callingSetup.api;

        api = await replaceAndReadyAPI(api, prospects, user, phone);

        api = api.replace('${phone}', phone);
        api = api.replace('${message}', decodeURIComponent(bodyContent));

        requestTime = new Date();

        let ssl = false;
        if (api.indexOf("https") > -1) {
            ssl = true;
        }
        try {
            //url = window.btoa(api);
            url = api;


            registerRequest = await API_POST("comm/CallAPI", {
                ssl,
                url
            });

        }
        catch(e){
            console.error(e);
        }


        //callingAPIRes = await API_GET(api);

        let APILog = {
            method: type,
            requestURL: api,
            requestPayload: {},
            requestTime,
            responseData: registerRequest,
            responseTime: new Date(),
            trackingId: prospects._id,
            user,
            admin: prospects.admin
        }
        await API_POST("action/addRecord", {
            root: "APILogs",
            body: APILog,
        });

        return true;
    }
    return false;

}




